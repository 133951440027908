<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <!--Breadcrumbs and title embedded within below data page-->
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000d2ba07de31b44c80852a/emb" :is="'script'">
        </component>
      </div>

      <div class="tabs">
        <div class="tab-header">

          <button class="tab-button" @click="this.$router.push('/distributor/call-logs')"
            :class="{ active: activeTab === 'call-logs' }">Call Logs</button>
          <button class="tab-button" @click="this.$router.push('/distributor/conversation-history')"
            :class="{ active: activeTab === 'conv-history' }">Conversation History</button>
          <button class="tab-button" @click="this.$router.push('/distributor/view-bot')"
            :class="{ active: activeTab === 'bot-config' }">Bot Configuration</button>
        </div>

        
        <div class="tab-content">
          <div v-if="activeTab === 'bot-config'">

            <div class="flex-container">
              <div class="left-section column">
                <div class="container">
                  <component src="https://c2hbe361.caspio.com/dp/76bfd000263eb90a3aca432cad2c/emb" :is="'script'"></component>
                </div>
                <div class="container">
                  <component src="https://c2hbe361.caspio.com/dp/76bfd00067e28c53fbba4edab7e7/emb" :is="'script'"></component>
                </div>
              </div>
              <div class="right-section column">
                <div class="container">
                  <component src="https://c2hbe361.caspio.com/dp/76bfd0001de933dfb3b140ff9307/emb" :is="'script'"></component>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>


      <!-- <div class="top-flex-container">
        <div class="top-left">
          <div class="container">
            <component src="https://c2hbe361.caspio.com/dp/76bfd000467aeb04f5bc4451b5fd/emb" :is="'script'"></component>
          </div> -->

          <!-- <h2>Bot Configuration</h2>
          <hr class="h2" />
        </div>
        <div class="top-right">
          <component src="https://c2hbe361.caspio.com/dp/76bfd000263eb90a3aca432cad2c/emb" :is="'script'"></component>
        </div>
      </div> -->

      <!-- <div class="flex-container">
        <div class="left-section column">
          <div class="container">
            <component src="https://c2hbe361.caspio.com/dp/76bfd00067e28c53fbba4edab7e7/emb" :is="'script'"></component>
          </div>
        </div>
        <div class="centre-section column">
          <Vue3Lottie :animationData="blobJSON" :height="300" :width="300" />
        </div>
        <div class="right-section column">
          <div class="container">
            <component src="https://c2hbe361.caspio.com/dp/76bfd0001de933dfb3b140ff9307/emb" :is="'script'"></component>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewBotConfig',
  components: {
    SideBar,
  },
  data() {
    return {
      activeTab: 'bot-config' // Default active tab
    }
  },
}
</script>

<style scoped>
.side-section {
  width: calc(100% / 4);
  /* Occupies one-third of the main content width */
  float: left;
  /* Position it to the right */
}

.flex-container,
.top-flex-container {
  display: flex;
  flex-direction: row;
}

.top-flex-container {
  text-align: -webkit-right;
}

.top-left {
  flex-grow: 1;
  justify-content: flex-start;
}

.top-right {
  flex-grow: 1;
  justify-content: flex-end;
}

.column {
  text-align: center;
  /* Center content within columns */
  /* Vertically center content within columns */
  margin: 0 auto;
}

.left-section {
  width: auto;
  display: inline-block;
}

.centre-section {
  max-width: 400px;
  padding-top: 40px;
}

.right-section {
  max-width: 220px;
}
</style>
