import { createRouter, createWebHistory } from 'vue-router';

/* Import general pages */
import HomeSignIn from './components/HomeSignIn.vue';
import AboutPage from './components/AboutPage.vue';
import ContactPage from './components/ContactPage.vue';

/* Import Customer Pages */
import CustomerPage from './components/Customer/CustomerLogin.vue';
import CustomerDashboard from './components/Customer/CustomerDashboard.vue';
import CustomerUpdateProfile from './components/Customer/UpdateProfile.vue';
import CustomerBotStatistics from './components/Customer/BotStatistics.vue';
import CustomerBots from './components/Customer/ViewBots.vue';
import CustomerBotDetails from './components/Customer/BotDetails.vue';
import CustomerCallLogs from './components/Customer/CallLogs.vue';
import CustomerBIlling from './components/Customer/ViewBills.vue';
import CustomerConvHistory from './components/Customer/ConversationHistory.vue';
import CustomerConvTranscript from './components/Customer/ConversationTranscript.vue';

/* Import Partner Pages */
import PartnerPage from './components/Partner/PartnerLogin.vue';
import PartnerDashboard from './components/Partner/PartnerDashboard.vue';
import PartnerUpdateProfile from './components/Partner/UpdateProfile.vue';
import PartnerNewCustomer from './components/Partner/NewCustomer.vue';
import PartnerManageCustomers from './components/Partner/ManageCustomers.vue';
import PartnerCustomerStatistics from './components/Partner/CustomerStatistics.vue';
import PartnerCustomerDetails from './components/Partner/CustomerDetails.vue';
import PartnerOpportunities from './components/Partner/NewOpportunities.vue';
import PartnerReleases from './components/Partner/ReleaseHistory.vue';

import PartnerCallLogs from './components/Partner/CallLogs.vue';
import PartnerConvHistory from './components/Partner/ConversationHistory.vue';
import PartnerConvTranscript from './components/Partner/ConversationTranscript.vue';

/* Import Bot Features Pages */

import PartnerNewBot from './components/Partner/NewBotConfiguration.vue';
import PartnerViewBot from './components/Partner/ViewBotConfiguration.vue';
import PartnerEditBot from './components/Partner/EditBotConfiguration.vue';
import PartnerBotFAQs from './components/Partner/Bot/BotFAQs.vue';
import PartnerBotTFAQs from './components/Partner/Bot/BotTFAQs.vue';
import PartnerBotBookings from './components/Partner/Bot/BotBookings.vue';
import PartnerBotEmails from './components/Partner/Bot/BotEmails.vue';
import PartnerBotData from './components/Partner/Bot/BotDataCollection.vue';
import PartnerBotTransfers from './components/Partner/Bot/BotTransfers.vue';
import PartnerBotGenAI from './components/Partner/Bot/BotGenAI.vue';

/* Import Distributor Pages */
import DistributorPage from './components/Distributor/DistributorLogin.vue';
import DistributorDashboard from './components/Distributor/DistributorDashboard.vue';
import DistributorUpdateProfile from './components/Distributor/UpdateProfile.vue';
import DistributorManagePartners from './components/Distributor/ManagePartners.vue';
import DistributorNewPartner from './components/Distributor/NewPartner.vue';
import DistributorPartnerStatistics from './components/Distributor/PartnerStatistics.vue';
import DistributorOpportunities from './components/Distributor/NewOpportunities.vue';
import DistributorReleases from './components/Distributor/ReleaseHistory.vue';

import DistributorCallLogs from './components/Distributor/CallLogs.vue';
import DistributorConvHistory from './components/Distributor/ConversationHistory.vue';
import DistributorConvTranscript from './components/Distributor/ConversationTranscript.vue';

import DistributorPartnerDetails from './components/Distributor/PartnerDetails.vue';
import DistributorNewCustomer from './components/Distributor/NewCustomer.vue';
import DistributorCustomerDetails from './components/Distributor/CustomerDetails.vue';
import DistributorNewBot from './components/Distributor/NewBotConfiguration.vue';
import DistributorViewBot from './components/Distributor/ViewBotConfiguration.vue';

/* Import Super Admin pages */
import AdminPage from './components/SuperAdmin/AdminLogin.vue';
import AdminDashboard from './components/SuperAdmin/AdminDashboard.vue';
import AdminReleases from './components/SuperAdmin/ReleaseHistory.vue';
import AdminOpportunities from './components/SuperAdmin/NewOpportunities.vue';
import AdminUpdateProfile from './components/SuperAdmin/UpdateProfile.vue';
import AdminManageDistributors from './components/SuperAdmin/ManageDistributors.vue';
import AdminManagePartners from './components/SuperAdmin/ManagePartners.vue';
import AdminNewDistributor from './components/SuperAdmin/NewDistributor.vue';

const routes = [
  { path: '/', component: HomeSignIn }, // Home route
  { path: '/home', component: HomeSignIn }, // Home route

  { path: '/customer', component: CustomerPage },
  { path: '/customer/dashboard', component: CustomerDashboard },
  { path: '/customer/update-profile', component: CustomerUpdateProfile },
  { path: '/customer/bots', component: CustomerBots },
  { path: '/customer/bot', component: CustomerBotDetails },
  { path: '/customer/bot-statistics', component: CustomerBotStatistics },
  { path: '/customer/call-logs', component: CustomerCallLogs },
  { path: '/customer/billing', component: CustomerBIlling },
  { path: '/customer/conversation-history', component: CustomerConvHistory },
  { path: '/customer/conversation-transcript', component: CustomerConvTranscript },

  { path: '/partner', component: PartnerPage },
  { path: '/partner/dashboard', component: PartnerDashboard },
  { path: '/partner/opportunities', component: PartnerOpportunities },
  { path: '/partner/update-profile', component: PartnerUpdateProfile },
  { path: '/partner/new-customer', component: PartnerNewCustomer },
  { path: '/partner/customers', component: PartnerManageCustomers },
  { path: '/partner/customer-statistics', component: PartnerCustomerStatistics },
  { path: '/partner/customer', component: PartnerCustomerDetails },
  { path: '/partner/releases', component: PartnerReleases },
  
  { path: '/partner/call-logs', component: PartnerCallLogs },
  { path: '/partner/conversation-history', component: PartnerConvHistory },
  { path: '/partner/conversation-transcript', component: PartnerConvTranscript },

  { path: '/partner/new-bot', component: PartnerNewBot },
  { path: '/partner/view-bot', component: PartnerViewBot },
  { path: '/partner/edit-bot', component: PartnerEditBot },
  { path: '/partner/bot-faqs', component: PartnerBotFAQs },
  { path: '/partner/bot-tfaqs', component: PartnerBotTFAQs },
  { path: '/partner/bot-transfers', component: PartnerBotTransfers },
  { path: '/partner/bot-emails', component: PartnerBotEmails },
  { path: '/partner/bot-bookings', component: PartnerBotBookings },
  { path: '/partner/bot-data', component: PartnerBotData },
  { path: '/partner/bot-genai', component: PartnerBotGenAI },


  { path: '/distributor', component: DistributorPage },
  { path: '/distributor/dashboard', component: DistributorDashboard },
  { path: '/distributor/opportunities', component: DistributorOpportunities },
  { path: '/distributor/update-profile', component: DistributorUpdateProfile },
  { path: '/distributor/new-partner', component: DistributorNewPartner },
  { path: '/distributor/releases', component: DistributorReleases },

  
  { path: '/distributor/partners', component: DistributorManagePartners },
  { path: '/distributor/partner-statistics', component: DistributorPartnerStatistics },
  { path: '/distributor/partner', component: DistributorPartnerDetails },
  { path: '/distributor/customer', component: DistributorCustomerDetails },
  { path: '/distributor/new-customer', component: DistributorNewCustomer },

  { path: '/distributor/new-bot', component: DistributorNewBot },
  { path: '/distributor/view-bot', component: DistributorViewBot },
  
  { path: '/distributor/call-logs', component: DistributorCallLogs },
  { path: '/distributor/conversation-history', component: DistributorConvHistory },
  { path: '/distributor/conversation-transcript', component: DistributorConvTranscript },

  { path: '/admin', component: AdminPage },
  { path: '/admin/dashboard', component: AdminDashboard },
  { path: '/admin/opportunities', component: AdminOpportunities },
  { path: '/admin/update-profile', component: AdminUpdateProfile },
  { path: '/admin/partners', component: AdminManagePartners },
  { path: '/admin/distributors', component: AdminManageDistributors },
  { path: '/admin/new-distributor', component: AdminNewDistributor },
  { path: '/admin/releases', component: AdminReleases },

  { path: '/about', component: AboutPage },
  { path: '/contact', component: ContactPage }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
