<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd00068f7e5a166af48629286/emb" :is="'script'"></component>
      </div>
      <h3>New Bot Discovery</h3>
      <div class="flex-container">
        <div class="container">
          <component src="https://c2hbe361.caspio.com/dp/76bfd000f93b3cbbccf74a2fa2ed/emb" :is="'script'"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'NewBot',
  components: {
    SideBar,
  },
}
</script>

<style scoped>
.sidebar {
  max-width: 10vw;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

h3 {
  margin-left: 20px;
  font-size: 24px;
  font-weight: normal;
}
</style>